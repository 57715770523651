import gql from 'graphql-tag'

export const listWalletItems = gql`query listWalletItems ($id: uuid!) {
  walletItems(id: $id) {
    id
    name
    dropName
    dropSlug
    thumbnailUrl
    rarityGrade
    rarityLabel
    rarityRank
  }
}`
